<template>
  <div class="user">

    <div class="header">
      <div class="user-info">
        <img src="@/assets/img/logo.png" class="us-tx" />
        <div>
          <div class="wxnickname">{{ abbr(us.userid, 4, 4) }}</div>
          <div class="row">
            <div class="tag">{{ us.ulevelName }}</div>
            <div class="tag" v-if="us.xlevel > 0">{{ us.xlevelName }}</div>
            <div class="tag" v-if="us.bdlevel > 0">{{ us.bdlevelName }}</div>
            <div class="tag" v-if="us.vlevel > 0">{{ us.vlevelName }}</div>
          </div>
        </div>
      </div>
      <div class="wallet">
        <div class="wallet-row">
          <div class="wallet-item" v-for="item in walletList" :key="item.id">
            <template v-if="item.cid == 5">
              <div class="wallet-label">理财投入</div>
              <div class="wallet-jine">{{ us.lskLicai }}</div>
            </template>
            <template v-else>
              <div class="wallet-label">{{ item.cnameZh }}</div>
              <div class="wallet-jine">{{ item.jine }}</div>
            </template>
          </div>
        </div>
        <div class="wallet-row" style="justify-content: space-between;">
          <div class="btn" @click="tolink('Bonus')">资金明细</div>
          <div class="btn" @click="tolink('Chongzhi')">充币</div>
          <div class="btn" @click="tolink('Tixian')">提币</div>
          <div class="btn" @click="tolink('inputOrder')">DG投入</div>
          <div class="btn" @click="tolink('Zhuanhuan')">兑换</div>
          <div class="btn" @click="tolink('Zhuanzhang')">互转</div>
        </div>
      </div>
    </div>

    <div class="menu">
      <div class="menu-item" @click="tolink('Bill')">
        <div class="row">
          <img src="@/assets/img/user/bill.svg" class="menu-icon" />
          <div>我的账单</div>
        </div>
        <q-icon name="arrow_forward_ios" class="menu-to-icon"></q-icon>
      </div>
      <div class="menu-item" @click="tolink('PoolsRecord')">
        <div class="row">
          <img src="@/assets/img/user/bill.svg" class="menu-icon" />
          <div>投入记录</div>
        </div>
        <div style="display: flex;align-items: center;">
          <div style="padding-right: 10px;width: 150px;overflow-x: auto;text-align: right;">锁仓DG：{{ suocangDG }}</div>
          <q-icon name="arrow_forward_ios" class="menu-to-icon"></q-icon>
        </div>
      </div>
      <div class="menu-item" @click="tolink('financialRecord')">
        <div class="row">
          <img src="@/assets/img/user/bill.svg" class="menu-icon" />
          <div>理财记录</div>
        </div>
        <q-icon name="arrow_forward_ios" class="menu-to-icon"></q-icon>
      </div>
      <div class="menu-item" @click="tolink('InputOrderRecord')">
        <div class="row">
          <img src="@/assets/img/user/bill.svg" class="menu-icon" />
          <div>DG投入记录</div>
        </div>
        <q-icon name="arrow_forward_ios" class="menu-to-icon"></q-icon>
      </div>
      <div class="menu-item" @click="tolink('Team')">
        <div class="row">
          <img src="@/assets/img/user/team.svg" class="menu-icon" />
          <div>我的团队</div>
        </div>
        <q-icon name="arrow_forward_ios" class="menu-to-icon"></q-icon>
      </div>
      <div class="menu-item" @click="tolink('fwzx')">
        <div class="row">
          <img src="@/assets/img/user/fwzx.svg" class="menu-icon" />
          <div>社区中心</div>
        </div>
        <q-icon name="arrow_forward_ios" class="menu-to-icon"></q-icon>
      </div>
      <div class="menu-item" @click="toShare">
        <div class="row">
          <img src="@/assets/img/user/fenxiang.svg" class="menu-icon" />
          <div>分享好友</div>
        </div>
        <q-icon name="arrow_forward_ios" class="menu-to-icon"></q-icon>
      </div>
      <div class="menu-item" @click="tolink('About')">
        <div class="row">
          <img src="@/assets/img/user/about.svg" class="menu-icon" />
          <div>关于我们</div>
        </div>
        <q-icon name="arrow_forward_ios" class="menu-to-icon"></q-icon>
      </div>
    </div>

    <CheckRegister @refresh="refresh"></CheckRegister>

    <TabBar active="3"></TabBar>
  </div>
</template>

<script>
import TabBar from '@/components/TabBar'
import { ref } from 'vue';
import CustomComponent from "@/components/CustomComponent";
import CheckRegister from '@/components/CheckRegister'

export default {
  name: '',
  components: {
    TabBar,
    CheckRegister
  },
  setup() {
    return {
      us: ref({}),
      walletList: ref([]),
      ulevel: ref(0),
      suocangDG: ref(0)
    }
  },
  created() {
    this.us = JSON.parse(this.$utils.getloc("us"))
    if (this.us) {
      this.getWallet()
      this.getUser()
    } else {
      this.us = {}
    }
  },
  methods: {
    refresh() {
      if (this.us) {
        this.getWallet()
        this.getUser()
      }
    },
    tolink(path) {
      this.$router.push(path)
    },
    swap(arr, i, j) {
      // 检查索引是否有效
      if (i >= 0 && i < arr.length && j >= 0 && j < arr.length) {
        [arr[i], arr[j]] = [arr[j], arr[i]];
      } else {
        console.error("Invalid indices provided for swapping.");
      }
    },
    toShare() {
      if (this.lskDg2usdt >= 1) {
        this.tolink('Share')
      } else {
        this.$q.dialog({
          component: CustomComponent,
          componentProps: {
            messages: ["账号未激活无法分享"],
            persistent: true,
          }
        });
      }
    },
    abbr(val, start, end) {
      if (val && val.length > 20) {
        let newVal = val.replace(
          val.substring(start, val.length - end),
          "****"
        )
        return newVal
      } else {
        return val
      }
    },
    getUser() {
      let _this = this
      _this.$request.post(
        "api/Users/Get",
        {
          token: _this.$utils.getloc("token"),
          userid: _this.us.userid,
        },
        (res) => {
          if (res.data.code == 0) {
            _this.$q.dialog({
              component: CustomComponent,
              componentProps: {
                messages: [res.data.msg],
                persistent: true,
              }
            });
            return
          }
          let data = res.data.data.us
          _this.suocangDG = res.data.data.suocangDG
          _this.ulevel = data.ulevel
          _this.lskDg2usdt = data.lskDg2usdt
        }
      )
    },
    getWallet() {
      let _this = this
      _this.$request.post(
        "api/Wallets/GetWallet",
        {
          token: _this.$utils.getloc("token"),
          userid: _this.us.userid,
        },
        (res) => {
          if (res.data.code == 0) {
            _this.$q.dialog({
              component: CustomComponent,
              componentProps: {
                messages: [res.data.msg],
                persistent: true,
              }
            })
            return
          }
          let data = res.data.data
          // 调换货币位置
          _this.swap(data, 3, 0)
          _this.swap(data, 2, 1)
          _this.swap(data, 2, 3)
          _this.walletList = data
        }
      )
    },
  }
}
</script>

<style scoped>
.header {
  padding: 15px 15px 35px;
  color: #fff;
  background-image: linear-gradient(to left bottom, #3c3526, #332f24, #2c2a22, #24241f, #1e1e1d);
}

.user-info {
  position: relative;
  padding: 15px 0;
  display: flex;
  align-items: center;
}

.equity {
  position: absolute;
  right: 10px;
  top: 20px;
  text-decoration: underline;
  color: #f9ae3d;
}

.userid {
  font-size: 14px;
  color: #aaaaaa;
}

.us-tx {
  margin-right: 10px;
  width: 50px;
  height: 50px;
}

.wallet {
  padding: 10px;
  background-color: #39342B;
  border-radius: 6px;
}

.wallet-row {
  display: flex;
  flex-wrap: wrap;
}

.wallet-item {
  width: 50%;
  text-align: center;
}

.wallet-item:nth-child(n + 3) {
  margin-top: 6px;
}

.wallet-label {
  color: #aaaaaa;
}

.wallet-jine {
  color: #FFD585;
}

.btn {
  margin-top: 15px;
  width: 31%;
  height: 28px;
  text-align: center;
  line-height: 28px;
  color: #FFD585;
  border-radius: 4px;
  border: 1px solid #FFD585;
  background-color: #4D4535;
  font-size: 13px;
}

.wxnickname {
  font-size: 15px;
}

.menu {
  margin-top: -10px;
  padding: 10px 10px 0;
  border-radius: 10px 10px 0 0;
  background-color: #111111;
}

.menu-item {
  padding: 15px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  border-bottom: 1px solid #1F1F1F;
}

.row {
  display: flex;
  align-items: center;
}

.menu-icon {
  margin-right: 10px;
  width: 20px;
  height: 20px;
}

.menu-to-icon {
  color: #999;
}

.tag {
  margin-top: 2px;
  padding: 1px 10px;
  color: #f9ae3d;
  border: 1px solid #f9ae3d;
  border-radius: 6px;
  font-size: 12px;
  margin-right: 5px;
}
</style>